import * as FrontEndUrl from '../constants/FrontendUrl';
import { DashboardOutlined, LogoutOutlined, AppstoreOutlined, AlertOutlined,
  TagOutlined, TeamOutlined, HomeOutlined, EnvironmentOutlined, FireOutlined } from '@ant-design/icons';

export const adminNavTree = [{
  key: 'dashboards',
  title: 'Dashboard',
  icon: DashboardOutlined,
  breadcrumb: true,
  submenu: [
    {
      breadcrumb: false,
      title: 'Onboardings',
      key: 'onboardings',
      icon: FireOutlined,
      path: FrontEndUrl.ONBOARDING.LIST,
    },
    {
      breadcrumb: false,
      key: 'commodities',
      title: 'Commodités',
      icon: AppstoreOutlined,
      submenu: [
        {
          breadcrumb: false,
          title: 'Catégories',
          key: 'commoditiy-categories',
          path: FrontEndUrl.HOUSE.COMMODITY.CATEGORY.LIST,
        },
        {
          breadcrumb: false,
          title: 'Commodités',
          key: 'commodity-self',
          path: FrontEndUrl.HOUSE.COMMODITY.LIST
        },
      ]
    },
    {
      breadcrumb: false,
      title: 'Catégories',
      key: 'categories',
      icon: TagOutlined,
      path: FrontEndUrl.HOUSE.CATEGORY.LIST,
    },
    {
      breadcrumb: false,
      title: 'Bailleurs',
      key: 'lessors',
      icon: TeamOutlined,
      path: FrontEndUrl.USER.LESSOR.LIST,
    },
    {
      breadcrumb: false,
      title: 'Logements',
      key: 'houses',
      icon: HomeOutlined,
      path: FrontEndUrl.HOUSE.LIST,
    },
    {
      breadcrumb: false,
      title: 'Villes populaires',
      key: 'popular-cities',
      icon: EnvironmentOutlined,
      path: FrontEndUrl.POPULAR_PLACE.CITY.LIST,
    },
    {
      breadcrumb: false,
      title: 'Lieux populaires',
      key: 'popular-places',
      icon: EnvironmentOutlined,
      path: FrontEndUrl.POPULAR_PLACE.POINT.LIST,
    },
    {
      breadcrumb: false,
      key: 'reports',
      title: 'Signalements',
      icon: AlertOutlined,
      submenu: [
        {
          breadcrumb: false,
          title: 'Types',
          key: 'report-types',
          path: FrontEndUrl.REPORT_TYPE.LIST,
        }
      ]
    },
  ]
}, {
  key: 'general',
  title: 'General',
  icon: DashboardOutlined,
  breadcrumb: true,
  submenu: [
    {
      key: 'general-logout',
      title: 'Déconnexion',
      icon: LogoutOutlined,
      breadcrumb: false,
      action: 'logout'
    },
  ]
}];

export const agentNavTree = [{
  key: 'dashboards',
  title: 'sidenav.dashboard',
  icon: DashboardOutlined,
  breadcrumb: true,
  submenu: [

  ]
}];
